import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import { GatsbyImage } from "gatsby-plugin-image";

import leafleft from "../images/client-login/vector-leaf-left.png";
import leafright from "../images/client-login/vector-leaf-right.png";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Client Login | Dark Horse Private Wealth"
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-[#F7F5EF] pt-16 pb-24 md:pt-22 md:pb-40 zigZagBg">
        <div className="container">
          <header className="mx-auto mb-14 text-center md:mb-20 md:max-w-[640px]">
            <h1 className="text-[2rem] md:text-[3.375rem]"><img src={leafleft} className="inline px-0 md:px-4"/>Client Login Portal<img src={leafright} className="inline px-0 md:px-4" /></h1>
          </header>
          <div className="grid gap-8 md:grid-cols-2 max-w-[1000px] mx-auto">
            <a
              href="https://wealth.emaplan.com/ema/SignIn?ema"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-10 py-10 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-10"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.taxPlanning.childImageSharp.gatsbyImageData}
                    className="h-10 w-10 mt-2"
                  />
                </div>
                <div>
                  <div className="heading-five">Your Dark Horse Private Wealth Personal Site</div>
                  
                  <div className="space-x-1.5 text-sm font-semibold text-[#E1C05F] transition-colors duration-300 ease-linear group-hover:text-[#E1C05F]">
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right group-hover:pl-[5px] duration-300 ease-linear"></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              href="https://client.schwab.com/Login/SignOn/CustomerCenterLogin.aspx"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-10 py-10 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-10"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.intuit.childImageSharp.gatsbyImageData}
                    className="h-10 w-10 mt-2"
                  />
                </div>
                <div>
                  <div className="heading-five">Your Charles Schwab Log-in Page</div>
                  
                  <div className="space-x-1.5 text-sm font-semibold text-[#E1C05F] transition-colors duration-300 ease-linear group-hover:text-[#E1C05F]">
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right group-hover:pl-[5px] duration-300 ease-linear"></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              href="https://app.humaninterest.com/login/"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-10 py-10 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-10"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.reporting.childImageSharp.gatsbyImageData}
                    className="h-10 w-10 mt-2"
                  />
                </div>
                <div>
                  <div className="heading-five">Your 401K / Qualified Plan Log In</div>
                  
                  <div className="space-x-1.5 text-sm font-semibold text-[#E1C05F] transition-colors duration-300 ease-linear group-hover:text-[#E1C05F]">
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right group-hover:pl-[5px] duration-300 ease-linear"></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              href="https://login.orionadvisor.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-10 py-10 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-10"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.aggregationAndPerformance.childImageSharp.gatsbyImageData}
                    className="h-10 w-10 mt-2"
                  />
                </div>
                <div>
                  <div className="heading-five">Your Account Aggregation and Performance Reporting Portal</div>

                  <div
                    className="space-x-1.5 text-sm font-semibold text-[#E1C05F] transition-colors duration-300 ease-linear group-hover:text-[#E1C05F]"
                  >
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right group-hover:pl-[5px] duration-300 ease-linear"></i>
                  </div>
                </div>
              </div>
            </a>

            
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    taxPlanning: file(relativePath: { eq: "client-login/dh-personal.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    intuit: file(relativePath: { eq: "client-login/schwab.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    quickbooks: file(relativePath: { eq: "client-login/quickbooks.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    reporting: file(relativePath: { eq: "client-login/401k.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    aggregationAndPerformance: file(relativePath: { eq: "client-login/portal-orion.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
  }
`;

export default Page;
